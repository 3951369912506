import React, { useState } from "react";
import PasswordIcon from "../../../assets/images/passwordIcon.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import useAuth from "../../../hooks/useAuth";
import Input from "../../../components/Form/Input";
import Notice from "../../../components/Notice";

const initialValues = {
  passwordCurrent: "",
  password: "",
  passwordConfirm: "",
};

const validationSchema = Yup.object({
  passwordCurrent: Yup.string().min(8, "Current Password must be at least 8 carasters").required("Password is required"),

  password: Yup.string()
    .min(8, "New Password must be at least 8 carasters")
    .required("New Password is required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "Both passwords must match")
    .required("Confirm Password is required"),
});

const EditPassword = ({ handleClose, id }) => {
  const [showPasswordCurrent, setshowPasswordCurrent] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });
  const [formError, setFormError] = useState({});
  const { method } = useAuth();
  const { editPassword } = useAuth();

  const showoldPasswordHandler = () => {
    setshowPasswordCurrent(!showPasswordCurrent);
  };

  const showPasswordHandler = () => {
    setshowPassword(!showPassword);
  };

  const showConfirmPasswordHandler = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (values, submitProps) => {
    try {
      await editPassword(
        values.passwordCurrent,
        values.password,
        values.passwordConfirm
      );
      setNotice({
        text: "SUCCES ! Password Updated Succesfully",
        open: true,
        state: "success",
      });
      setTimeout(() => {
        handleClose(id);
      }, 1500);
      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
    } catch (err) {
      if (err.message) {
        setNotice({
          text: err.message,
          open: true,
          state: "error",
        });
      }
      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };
  return (
    <>
      {method === "JWT" && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            setFormError(formik.errors);
            return (
              <>
                <Notice
                  open={notice.open}
                  text={notice.text}
                  state={notice.state}
                />
                <Form>
                  <div>
                    <Input
                      type={`${showPasswordCurrent ? "text" : "password"}`}
                      label="Your Password"
                      name="passwordCurrent"
                      placeholder="Your Password"
                      errorMessage={formError.passwordCurrent}
                      className={"form-control-input-with-icons"}
                      withIcon
                      leftIcon={PasswordIcon}
                      rightIcon={
                        showPasswordCurrent ? (
                          <VisibilityOutlinedIcon
                            onClick={showoldPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            onClick={showoldPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        )
                      }
                    />
                    <Input
                      type={`${showPassword ? "text" : "password"}`}
                      label="New Password"
                      name="password"
                      placeholder="Your New Password"
                      errorMessage={formError.password}
                      className={"form-control-input-with-icons"}
                      withIcon
                      leftIcon={PasswordIcon}
                      rightIcon={
                        showPassword ? (
                          <VisibilityOutlinedIcon
                            onClick={showPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            onClick={showPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        )
                      }
                    />

                    <Input
                      type={`${showConfirmPassword ? "text" : "password"}`}
                      label="Confirm Password"
                      name="passwordConfirm"
                      placeholder="Confirm New Password"
                      errorMessage={formError.passwordConfirm}
                      className={"form-control-input-with-icons"}
                      withIcon
                      leftIcon={PasswordIcon}
                      rightIcon={
                        showConfirmPassword ? (
                          <VisibilityOutlinedIcon
                            onClick={showConfirmPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            onClick={showConfirmPasswordHandler}
                            htmlColor="#B0B2B8"
                          />
                        )
                      }
                    />

                    <Button
                      type="submit"
                      disabled={!formik.isValid || formik.isSubmitting}
                      text="Edit Password"
                      className={
                        !formik.isValid || formik.isSubmitting
                          ? "disabled-button login-btn"
                          : "login-btn"
                      }
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditPassword;
