import PersonalLeave from "../assets/icons/PersonalLeave.png";
import leaveVacation from "../assets/icons/leaveVacation.png";
import sickLeave from "../assets/icons/sickLeave.png";
import CasualLeave from "../assets/icons/CasualLeave.png";

export const tabsOptionsDshboard = [
  { value: 0, label: "Last Leave Request" },
  { value: 1, label: "Last Authorisation Request" },
];
export const tabsOptionsHistorique = [
  { value: 0, label: " Leave History" },
  { value: 1, label: " Authorisation History" },
];
export const tabsOptionsUserDetails = [
  { value: 0, label: "Last Approved Leave " },
  { value: 1, label: "Last Approved Authorisation " },
];
export const tabsMySpaceOptions = [
  { value: 0, label: "My Leaves Space" },
  { value: 1, label: "My Authorisations Space" },
];
export const OptionsPermission = [
  { id: 2, value: "LIST", name: "LIST" },
  { id: 3, value: "CREATE", name: "CREATE" },
  { id: 4, value: "EDIT", name: "EDIT" },
  { id: 5, value: "VIEW", name: "VIEW" },
  { id: 6, value: "DELETE", name: "DELETE" },
  { id: 7, value: "APPROVE", name: "APPROVE" },
  { id: 8, value: "REJECT", name: "REJECT" },
];
export const methods = [
  { id: 1, methode: "Leave resuqest Number", key: "Leave resuqest Number" },
  { id: 2, methode: "Leave type", key: "Leave type" },
  { id: 3, methode: "Department", key: "Department" },
  { id: 4, methode: "Leave pending", key: "Leave pending" },
  { id: 5, methode: "Role", key: "Role" },
];
////
export const optionsStatus = [
  { value: "Full-time", label: "Full-time" },
  { value: "Part-time", label: "Part-time" },
];
export const options = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const LeavesStatus = [
  {
    id: 0,
    code: ["waiting-tech-approval"],
    name: "Waiting for technical approval",
  },
  { id: 1, code: ["waiting-hr-approval"], name: "Waiting for HR approval" },
  { id: 2, code: ["approved"], name: "Approved" },
  { id: 3, code: ["rejected"], name: "Rejected" },
  { id: 4, code: ["waiting-employee-negotiate"], name: "Negotiation" },
];

export const MySpaceStatus = [
  {
    id: 0,
    code: ["waiting-tech-approval", "waiting-hr-approval"],
    name: "Waiting for approval",
  },
  { id: 1, code: ["approved"], name: "Approved" },
  { id: 2, code: ["rejected"], name: "Rejected" },
  { id: 3, code: ["waiting-employee-negotiate"], name: "Negotiation" },
];

export const leavesInfo = [
  {
    label: "Annual Leave Balance",
    code: "leave-balance",
  },
  {
    label: "Total Leave Days",
    code: "total-leave-days",
  },
  {
    label: "Monthly Balance",
    code: "balance",
  },
  {
    label: "Authorisation Balance",
    code: "authorisationBalance",
  },
];

export const MySpaceHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "Period" },
  { id: 3, thead: "Type" },
  { id: 4, thead: "Days requested" },
  { id: 5, thead: "Days left" },
  { id: 4, thead: "Status" },
  { id: 5, thead: "Control" },
];

export const LeavesHeaderAdmin = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "From -To" },
  { id: 3, thead: "Type" },
  { id: 4, thead: "Days requested" },
  { id: 5, thead: "Days left" },
  { id: 6, thead: "Status" },
  { id: 7, thead: "Delete Leave" },
];

export const LeavesHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "From -To" },
  { id: 3, thead: "Type" },
  { id: 4, thead: "Days requested" },
  { id: 5, thead: "Days left" },
  { id: 6, thead: "Status" },
  { id: 7, thead: "Control" },
];

export const LeavesAllowedList = [
  "type",
  "user",
  "status",
  "dateStart",
  "dateEnd",
  "leaveLength",
  "balance",
];

export const AuthorisationsAllowedList = [
  "user",
  "date",
  "startTime",
  "endTime",
  "authorisationLength",
  "authorisationBalanceLeft",
  "status",
];

export const AuthorisationsHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "Date" },
  { id: 3, thead: "Time" },
  { id: 4, thead: "Hours requested" },
  { id: 5, thead: "Hours left" },
  { id: 4, thead: "Status" },
  { id: 5, thead: "Control" },
];

export const AuthorationsHeaderAdmin = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "Date" },
  { id: 3, thead: "Time" },
  { id: 4, thead: "Hours requested" },
  { id: 5, thead: "Hours left" },
  { id: 6, thead: "Status" },
  { id: 7, thead: "Delete Leave" },
];

export const AuthorisationsSortBy = [
  "user",
  "authorisationDate",
  "authorisationTime",
  "authorisationLength",
  "authorisationBalanceLeft",
  "status",
];

export const LeavesSortBy = [
  "user",
  "leaveDate",
  "type",
  "leaveLength",
  "balance",
  "status",
];

export const EmployeesHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "Department" },
  { id: 3, thead: "Status" },
  { id: 4, thead: "Contacts" },
  { id: 5, thead: "Actions" },
];

export const EmployeesAllowedList = [
  "status",
  "fullName",
  "jobTitle",
  "department",
  "email",
  "phone",
  "img",
];

export const EmployeesSortBy = ["user", "department", "status", "userInfo"];

export const LeaveTypeHeader = [
  { id: 1, thead: "Icon" },
  { id: 2, thead: "Name" },
  { id: 3, thead: "Preview" },
  { id: 4, thead: "Action" },
];

export const LeaveTypeHeaderTeamEmployee = [
  { id: 1, thead: "Icon" },
  { id: 2, thead: "Name" },
  { id: 3, thead: "Preview" },
];

export const PermissionHeader = [
  { id: 1, thead: "Roles/Actions ", name: "" },
  { id: 2, thead: "List", name: "LIST" },
  { id: 3, thead: "Create", name: "CREATE" },
  { id: 4, thead: "Edit", name: "EDIT" },
  { id: 5, thead: "View", name: "VIEW" },
  { id: 6, thead: "Delete", name: "DELETE" },
  { id: 7, thead: "Approve", name: "APPROVE" },
  { id: 8, thead: "Reject", name: "REJECT" },
];

export const LeaveTypeAllowed = ["img", "name", "color", "preview"];

export const LeaveTypeSortBy = ["img", "name", "preview"];

export const LeavesHistoryHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "From -To" },
  { id: 3, thead: "Type" },
  { id: 6, thead: "Status" },
  { id: 7, thead: "Approved by TL at" },
  { id: 8, thead: "Approved by RH at" },
  { id: 7, thead: "Rejected by TL at" },
  { id: 8, thead: "Rejected by RH at" },
  { id: 8, thead: "Rejected Negotiation at" },
];

export const AuthorisationHistoryHeader = [
  { id: 1, thead: "Name" },
  { id: 2, thead: "Date" },
  { id: 3, thead: "Time" },
  { id: 6, thead: "Status" },
  { id: 7, thead: "Approved by TL at" },
  { id: 8, thead: "Approved by RH at" },
  { id: 7, thead: "Rejected by TL at" },
  { id: 8, thead: "Rejected by RH at" },
];
export const LeavesHistoryAllowed = [
  "type",
  "user",
  "status",
  "dateStart",
  "dateEnd",
  "dateApprovedTL",
  "dateApprovedRH",
  "dateRejectTL",
  "dateRejectRH",
  "dateRejectNegotiation",
];
export const AuthorisationsHistoryAllowed = [
  "user",
  "date",
  "startTime",
  "endTime",
  "status",
  "dateApprovedTL",
  "dateApprovedRH",
  "dateRejectTL",
  "dateRejectRH",
];
export const AuthorisationsHistorySortBy = [
  "user",
  "authorisationDate",
  "authorisationTime",
  "status",
  "dateApprovedTL",
  "dateApprovedRH",
  "dateRejectTL",
  "dateRejectRH",
];
export const LeavesHistorySortBy = [
  "user",
  "leaveDate",
  "type",
  "status",
  "dateApprovedTL",
  "dateApprovedRH",
  "dateRejectTL",
  "dateRejectRH",
  "dateRejectNegotiation",
];
export const LogHeader = [
  { id: 1, thead: "Action" },
  { id: 2, thead: "Date" },
  { id: 3, thead: "User" },
];
export const LogAllowed = [];
export const LogSortBy = [];
export const width1 = { width: "75px" };
export const width2 = { width: "90px" };

export const SickColor = {
  color: "#FA3839",
};

export const BlackColor = {
  color: "#000000",
};
export const CasualColor = {
  color: "#784DF5",
};
export const PersonalColor = {
  color: "#0560FC",
};
export const VacationColor = {
  color: "#F18541",
};
export const width = { width: "94px" };

export const borderRed = {
  border: "1px solid #df2020",
  color: "#df2020",
};

export const colorRed = {
  color: "#df2020",
};
export const colorBlue = {
  color: "#4588FD",
};
export const borderBlue = {
  border: "1px solid #4588FD",
};
export const trendingUpColor = {
  color: "#9B9EAD",
};

export const trendingUpborder = {
  border: "1px solid #9B9EAD",
};

//select options

export const leaveType = [
  { key: "test" },
  { key: "Sick Leave" },
  { key: "Personal Leave" },
  { key: "Vacation Leave" },
  { key: "Casual Leave" },
];

export const holidayType = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Unpaid" },
];
export const optionsDep = [
  { key: "test" },
  { key: "Marketing" },
  { key: "Developement" },
];

export const Roles = [
  { key: "RH" },
  { key: "Team leader" },
  { key: "Employee" },
];

export const PersonalInfo = [
  { label: "ID" },
  { label: "Full Name" },
  { label: "Email Address" },
  { label: "Phone Number" },
  { label: "Birthday" },
  { label: "Gender" },
  { label: "Address" },
];

export const ProInfo = [
  { label: "Hiring Date" },
  { label: "Job Title" },
  { label: "Department" },
  { label: "Satuts" },
  { label: "Role" },
  { label: "Balance" },
];

export const leaveBalanceType = [
  {
    label: "Sick",
    column: "LeaveType",
    img: PersonalLeave,
    leaveDays: "9",
    period: "15 Jan - 20 Jan",
    status: "Approved",
    TotalDays: "10",
  },
  {
    label: "Casual",
    column: "TotalBalance",
    img: leaveVacation,
    leaveDays: "10",
    period: "15 Jan - 20 Jan",
    status: "Approved",
    TotalDays: "10",
  },
  {
    label: "Personal",
    column: "AvailableLeaves",
    img: sickLeave,
    leaveDays: "11",
    period: "15 Jan - 20 Jan",
    status: "Approved",
    TotalDays: "10",
  },
  {
    label: "Vacation",
    column: "VacationLeave",
    img: CasualLeave,
    leaveDays: "12",
    period: "15 Jan - 20 Jan",
    status: "Approved",
    TotalDays: "10",
  },
];

export const colNames = [
  { label: "Leave Type" },
  { label: "Total Balance" },
  { label: "Available Leaves" },
];

export const UserInfo = [
  { label: "Full Name", code: "fullName" },
  { label: "Birthday", code: "birthday" },
  { label: "Gender", code: "gender" },
];
export const JobInfo = [
  { label: "Job Title", code: "jobTitle" },
  { label: "Departement", code: "department" },
  { label: "Status", code: "status" },
];

export const UserContactInfo = [
  { label: "Email", code: "email" },
  { label: "Phone Number", code: "phone" },
  { label: "Address", code: "address" },
];

export const RejectLeaveReasons = [
  { value: "no balance left", label: "no balance left" },
  { value: "finish your task", label: "finish your task" },
  {
    value: "another member of the team has the same date vacation",
    label: "another member of the team has the same date vacation",
  },
  { value: "Other...", label: "Other..." },
];

export const RejectAuthorisationReasons = [
  { value: "no balance left", label: "no balance left" },
  { value: "finish your task", label: "finish your task" },

  { value: "Other...", label: "Other..." },
];
