import React, { useState } from "react";
import Button from "../../../components/Button";
import Upload from "../../../components/UploadImg/index";
import { deleteDepartment } from "../../../slices/departement";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../slices/modals";
import Textarea from "../../../components/Form/Textarea";
import Input from "../../../components/Form/Input";
const AddDep = ({
  setImageName,
  img,
  formik,
  className,
  isUpdate,
  id,
  handleClose,
  setMyImg,
  myImg,
  setUpdate,
  setNoticeDelete,
}) => {
  const dispatch = useDispatch();
  const dep = useSelector((state) => state.modals.modals[0].data);
  const [resetImg, setResetImg] = useState(false);
  const resetHandler = () => {
    setResetImg(true);
    setMyImg(false);
  };

  const handldelete = async () => {
    dispatch(closeModal("confirm-delete-modal"));

    await dispatch(deleteDepartment(dep.id)).then((result) => {
      if (result?.payload?.message) {
        setUpdate(false);
        setNoticeDelete({
          text: "Department has been successfully deleted!",
          open: true,
          state: "success",
        });

        setTimeout(() => {
          setNoticeDelete({
            open: false,
          });
        }, 1500);
      }
    });
  };

  return (
    <>
      <div className="NewDepForm-wrapper">
        <Upload
          name="img"
          formik={formik}
          isUpoding={true}
          img={img}
          setMyImg={setMyImg}
          myImg={myImg}
          setImageName={setImageName}
          setResetValue={setResetImg}
          resetValue={resetImg}
          isUpdate={isUpdate}
        />
        <Input type="text" label="Department Name" name="name" />
        <Textarea
          type="textarea"
          label="Department Description"
          name="description"
        />
      </div>
      <div className="add-new-dep-button">
        <div className="ta-btns-group">
          <Button
            type="submit"
            text={isUpdate ? "Edit" : "Save"}
            className={className}
          />
          <Button
            className={isUpdate ? "redButton" : "whiteButton"}
            type={isUpdate ? "button" : "reset"}
            text={isUpdate ? "Delete" : "Cancel"}
            onClick={
              isUpdate
                ? () =>
                    dispatch(
                      isUpdate
                        ? handleClose(id) ||
                            openModal("confirm-delete-modal", {
                              id: dep.id,
                              Item: "department",
                              deleteHandler: handldelete,
                            })
                        : null
                    )
                : resetHandler
            }
          />
        </div>
      </div>
    </>
  );
};

export default AddDep;
