import React from "react";
const Td = ({
  icon,
  text,
  description,
  className,
  imgSize,
  tdContentClassName,
  ...res
}) => {
  return (
    <>
      <div className={imgSize ? `${imgSize} td` : "td"}>
        {icon &&
          (typeof icon === "object" ? icon : <img src={icon} alt="img" />)}
        <div className={tdContentClassName}>
          <div className={`text ${className}`} {...res}>
            {text}
          </div>
          {description && <div className="description">{description}</div>}
        </div>
      </div>
    </>
  );
};

export default Td;
