import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AddEmplFoms from "./WrapperFoms";
import "./_addEmpl.scss";
const ModalExample = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal addUserModal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_header">
        <span className="title-modals"> {title} </span>
        <div className="close_button">
          <CloseIcon onClick={() => handleClose(id)} />
        </div>
      </DialogTitle>
      <DialogContent>
        <AddEmplFoms handleClose={handleClose} id={id} />
      </DialogContent>
    </Dialog>
  );
};

export default ModalExample;
