export const allLeavesDay = (dateHiring) => {
  var date_1 = currentDate();
  let date_2 = new Date(dateHiring);
  let difference = date_1.getTime() - date_2.getTime();

  let TotalDays = Math.floor(difference / (1000 * 3600 * 24)) + 1;

  return TotalDays;
};

export const currentDate = () => {
  var today = new Date();
  var date_01 =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var date_1 = new Date(date_01);
  return date_1;
};
