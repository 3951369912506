import { Suspense, Fragment, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import LoadingScreen from "./components/LoadingScreen";
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";

export const RenderRoutes = ({ routes = [] }) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? (
                    <RenderRoutes routes={route.routes} />
                  ) : (
                    <Component />
                  )}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/sign-in",
    element: lazy(() => import("./views/SignIn")),
  },

  {
    path: "/*",
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        path: "/",
        element: lazy(() => import("./views/Dashboard")),
      },
      {
        path: "/myspace",
        element: lazy(() => import("./views/MySpace")),
      },
      {
        path: "/employees",
        element: lazy(() => import("./views/Employees")),
      },

      {
        path: "/leaves",
        element: lazy(() => import("./views/Leaves")),
      },
      {
        path: "/authorisations",
        element: lazy(() => import("./views/Authorisation")),
      },
      {
        path: "settings/holidays",
        element: lazy(() => import("./views/Settings/holidays")),
      },

      {
        path: "settings/departement",
        element: lazy(() => import("./views/Settings/Departement")),
      },
      {
        path: "settings/leave-settings",
        element: lazy(() => import("./views/Settings/LeaveSettings")),
      },
      {
        path: "/history/leaves-history",
        element: lazy(() => import("./views/History/LeavesHistory")),
      },

      {
        path: "/history/log",
        element: lazy(() => import("./views/History/Log")),
      },

      {
        //   exact: true,
        path: "/permissions/admin-users",
        element: lazy(() => import("./views/Permissions/AdminUsers")),
      },
      {
        // exact: true,

        path: "/permissions/group-permissions",
        element: lazy(() => import("./views/Permissions/GroupPermissions")),
      },

      {
        //  exact: true,
        path: "/history/leaves-history",
        element: lazy(() => import("./views/History/LeavesHistory")),
      },
      // {
      //   //  exact: true,
      //   path: "/history/log",
      //   element: lazy(() => import("./views/History/Log")),
      // },
      {
        //  exact: true,
        path: "settings/user-settings",
        element: lazy(() => import("./views/Settings/userSettings")),
      },
      {
        path: "*",
        element: lazy(() => import("./views/NotFound")),
      },
    ],
  },
];

export default routes;
