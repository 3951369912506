import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import Upload from "../../../components/UploadImg/index";
import "./_leaveSettings.scss";
import { createLeaveType, updateLeaveTypes } from "../../../slices/leavetype";
import { useDispatch } from "react-redux";
import Notice from "../../../components/Notice";
import Input from "../../../components/Form/Input";
import ColorPicker from "../../../components/Form/ColorPicker";

function LeaveSettingsForm({
  handleClose,
  id,
  row,
  updateLeaveType,
  updateleaveimg,
  isUpdate,
}) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(updateLeaveType ? row?.color : "");
  const [imageName, setImageName] = useState();

  const initialValues =
    row?.img === imageName
      ? {
          name: updateLeaveType ? row?.name : "",
          color: updateLeaveType ? row?.color : "#121212",
          img: row?.img,
        }
      : {
          name: updateLeaveType ? row?.name : "",
          color: updateLeaveType ? row?.color : "#121212",
        };

  const [myImg, setMyImg] = useState(false);
  const [resetImg, setResetImg] = useState(false);
  const [resetColor, setResetColor] = useState(false);

  const resetHandler = () => {
    setResetImg(true);
    setMyImg(false);
    setResetColor(true);
  };
  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Leave type Name is required ! "),
  });

  const onSubmit = async (values, submitProps) => {
    const data = {
      name: values?.name,
      color: values?.color,
      img: values?.img,
    };

    updateLeaveType === true
      ? await dispatch(updateLeaveTypes({ ...data, id: row?._id })).then(
          (res) => {
            if (res?.error) {
              setNotice({
                text: res?.error?.message,
                open: true,
                state: "error",
              });
              submitProps.setStatus({ success: false });
              submitProps.setSubmitting(true);
            } else {
              if (res?.payload?.message) {
                setNotice({
                  text: res?.payload?.message,
                  open: true,
                  state: "success",
                });
                submitProps.setStatus({ success: true });
                submitProps.setSubmitting(true);

                setTimeout(() => {
                  handleClose(id);
                }, 1500);
              }
            }
          }
        )
      : await dispatch(createLeaveType(data)).then((res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            } else {
            }
          }
        });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Notice
              open={notice.open}
              text={notice.text}
              state={notice.state}
            />
            <Form>
              <div className="span1-style">
                <span>New Leave Icon</span>
              </div>

              <div style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Upload
                  isUpoding={true}
                  name="img"
                  img={row?.img}
                  formik={formik}
                  isUpdate={updateLeaveType === true ? true : false}
                  myImg={updateleaveimg ? true : myImg}
                  setMyImg={setMyImg}
                  setImageName={setImageName}
                  setResetValue={setResetImg}
                  resetValue={resetImg}
                />
              </div>
              <div className="span1-style">
                <span>Leave Details</span>
              </div>

              <div className="leaveSettings">
                <Input
                  type="text"
                  label="Leave Name"
                  name="name"
                  color={resetColor ? "" : value}
                />

                <ColorPicker
                  setResetValue={setResetColor}
                  resetValue={resetColor}
                  setValue={setValue}
                  name="color"
                  formik={formik}
                  initialColor={row?.color}
                />
              </div>

              <div className="ta-btns-group">
                <Button
                  type="submit"
                  className={
                    !formik.isValid || formik.isSubmitting
                      ? " blueButton disabled-button "
                      : "blueButton "
                  }
                  text={updateLeaveType ? "Edit" : "Submit"}
                />
                {!updateLeaveType && (
                  <Button
                    className={"whiteButton"}
                    type={"reset"}
                    text={"Cancel"}
                    onClick={resetHandler}
                  />
                )}
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default LeaveSettingsForm;
