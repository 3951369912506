import React, { useEffect, useState } from "react";
import "./_style.scss";
import Avatar from "../../components/Header/Avatar";
import { useSelector } from "react-redux";
import { avatarByGender } from "../../utilities/AvatarByGender";
const Upload = (props) => {
  const {
    isUpoding,
    img,
    uploadPhoto,
    formik,
    name,
    myImg,
    setMyImg,
    width,
    height,
    updatedClasse,
    resetValue,
    setResetValue,
    isUpdate,
    addUser,
  } = props;
  const { users } = useSelector((state) => state.user.user);
  const Genders = users?.map((USER) => USER?.gender);
  const [image, setImage] = useState(`${process.env.REACT_APP_IMG_URL}/${img}`);

  const handleChange = (event) => {
    if (resetValue) {
      setResetValue(false);
    }
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    formik.values.img = event.target.files[0];
    setMyImg(true);
  };
  return (
    <div className="wrraper-photo-upload">
      <div className="photo-upload-form">
        {uploadPhoto || addUser ? (
          <divName
            Department
            Status
            Contacts
            Actions
            className={`${
              updatedClasse
                ? updatedClasse
                : "photo-upload wrraper-photo-upload "
            }`}
          >
            <Avatar
              img={resetValue ? "" : avatarByGender(image, Genders)}
              id="img-preview"
              width={width}
              height={height}
            />
          </divName>
        ) : (
          <div className="photo-upload button-upload ">
            {myImg && (
              <img id="target" src={resetValue ? "" : image} alt="upload" />
            )}
          </div>
        )}

        {isUpoding && (
          <>
            <input
              name={name}
              onChange={handleChange}
              type="file"
              required={!isUpdate ? true : false}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Upload;
