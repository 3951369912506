import React from "react";

const EmployeeItem = (props) => {
  const { label, info } = props;

  return (
    <div className="info_wrapper ">
      <span style={{ color: "#9B9EAD" }}>{label}</span>
      <span>{info}</span>
    </div>
  );
};

export default EmployeeItem;
