import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modals: [
    {
      id: "UpdateDep",
      open: false,
      data: [],
    },
    {
      id: "UpdateUserModal",
      open: false,
    },

    {
      id: "AddHolyModal",
      open: false,
    },
    {
      id: "EmpDetModal",
      open: false,
      data: false,
    },

    {
      id: "AddEmplFoms",
      open: false,
    },
    {
      id: "ApplyForLeave",
      open: false,
    },
    {
      id: "AddNewDepModal",
      open: false,
    },
    {
      id: "ApplyForNegotiationModal",
      open: false,
    },
    {
      id: "LeaveSettingsModal",
      open: false,
    },
    {
      id: "ApplyForLeaveDetailsModal",
      open: false,
    },
    {
      id: "UpdateLeaveSettingsModal",
      open: false,
    },
    {
      id: "confirm-delete-modal",
      open: false,
    },
    {
      id: "ConfirmDeletePerGrpModal",
      open: false,
    },
    {
      id: "reject-leave-request-modal",
      open: false,
    },
    {
      id: "ApplyForAuthorisationModal",
      open: false,
    },
    {
      id: "ApplyForAuthorisationModalDetails",
      open: false,
    },
    {
      id: "editPasswordModal",
      open: false,
    },
  ],
};

const slice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { id, data } = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = true;
      state.modals[index].data = data;
    },
    closeModal: (state, action) => {
      const id = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      state.modals[index].open = false;
    },
  },
});

export const reducer = slice.reducer;

export const openModal = (id, data) => (dispatch) => {
  dispatch(slice.actions.openModal({ id, data }));
};

export const closeModal = (id) => (dispatch) => {
  dispatch(slice.actions.closeModal(id));
};

export default slice;
