import "./_logo.scss";
import Leavemetry from "../../assets/icons/Logo.png";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";

const Logo = () => {
  return (
    <div className="logo_Leavemetry">
      <DensitySmallIcon />
      <img src={Leavemetry} alt="Leavemetry img" />
    </div>
  );
};

export default Logo;
