import React from "react";

import { options } from "../../utilities/constants";
import DatePicker from "../../components/Form/DatePicker";
import Select from "../../components/Form/Select";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";

const FormikComponent = ({
  otherstyle,
  formik,
  setResetValue,
  resetValue,
  disabled,
  setFieldChange,
}) => {
  return (
    <>
      <Input
        type="text"
        label="Full Name"
        name="fullName"
        errorWraper="errorWraper-add-user"
      />
      <Input
        type="string"
        label="Phone Number"
        name="phone"
        errorWraper="errorWraper-add-user"
      />
      <DatePicker
        formik={formik}
        type="date"
        label="Birthday"
        name="birthday"
        errorWraper="errorWraper-add-user"
        error={
          formik.errors.birthday && formik.touched.birthday
            ? formik.errors.birthday
            : null
        }
      />
      <Input
        type="email"
        label="Email"
        name="email"
        errorWraper="errorWraper-add-user"
        isDisabled={disabled ? true : false}
      />

      <Select
        label="Gender"
        id="gender"
        setResetValue={setResetValue}
        resetValue={resetValue}
        options={options}
        value={formik.values.gender}
        onChange={(value) => formik.setFieldValue("gender", value.label)}
        error={
          formik.errors.gender && formik.touched.gender
            ? formik.errors.gender
            : null
        }
        className={`${
          formik.errors.gender && formik.touched.gender
            ? "selectForm  error-select"
            : otherstyle
            ? "otherstyle selectForm"
            : "selectForm"
        }`}
      />
      <Input type="text" label="Address" name="address" />
    </>
  );
};

export default FormikComponent;
