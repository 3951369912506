import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./_sidebar.scss";
import { useSelector } from "react-redux";

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
`;

const DropdownLink = styled(Link)`
  margin-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #555555;
  font-size: 18px;
  border-left: 2px solid #eeeeee;
`;

const SubMenu = ({
  item,
  onClick,
  Id,
  setId,
  show,
  loc,
  setSHow,
  location,
}) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => {
    return setSubnav(!subnav);
  };
  const handleChange = (id) => {
    setId(id);
  };
  const fetchMeStatus = useSelector((state) => state.user.fetchMeStatus);
  const SidebarNav = styled.nav``;

  return (
    <>
      {fetchMeStatus === "succeeded" && (
        <>
          <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
            <div className="active-navlink">
              {show ? (
                <div
                  onClick={onClick}
                  className={
                    loc ? "ClickedNav normal-navlink" : "normal-navlink "
                  }
                >
                  <img src={item.icon} alt="img" />
                  <>
                    <span> {item.label} </span>

                    {item.subNav && subnav
                      ? item.iconOpened
                      : item.subNav
                      ? item.iconClosed
                      : null}
                  </>
                </div>
              ) : (
                <>
                  <div
                    onClick={() =>
                      item.label === "Settings" ||
                      item.label === "Permissions" ||
                      item.label === "History"
                        ? setSHow(true)
                        : ""
                    }
                  >
                    <img src={item.icon} alt="img" />
                  </div>
                </>
              )}
            </div>
          </SidebarLink>

          {show ? (
            <div className="subNav_container">
              {subnav &&
                item.subNav.map((SUBitem, index) => {
                  return (
                    <DropdownLink to={SUBitem.path} key={index}>
                      <div
                        style={{ paddingLeft: "8px", marginLeft: "12px" }}
                        onClick={() => {
                          handleChange(SUBitem.id, SUBitem);
                        }}
                        className={
                          Id === SUBitem.id ||
                          SUBitem.path === location?.pathname
                            ? "ClickedNav normal-navlinkMin"
                            : "normal-navlinkMin"
                        }
                      >
                        {show ? SUBitem.label : ""}
                      </div>
                    </DropdownLink>
                  );
                })}
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default SubMenu;
