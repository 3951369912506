import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import { TablePagination } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Row from "./Components/Row";
import CheckBoxRow from "./Components/CheckBoxRow";
import Search from "../../utilities/Search";
import "./index.scss";
const TableComponent = ({
  header,
  rows,
  className,
  tableName,
  isAction,
  isCollapsed,
  isNegotiation,
  isMySpace,
  isAuthorisation,
  allowed,
  sortBy,
  getLeaveStatus,
  leaveTypes,
  value,
  isPaging,
  setNotice,
  leaveID,
  AllPermissions,
  options,
  permission,

  userPermissions,
  userId,
  GroupPermissionsTable,
  AllPermissionGroups,
  reset,
  setReset,
  permsionPath,
  permissiongroupestatus,
}) => {
  const Rows = Search(rows, value, tableName);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  useEffect(() => {
    if (leaveID) {
      setPage(0);
      Rows?.map((row, index) =>
        row._id === leaveID
          ? setPage(Math.ceil((index + 1) / rowsPerPage) - 1)
          : ""
      );
    }
  }, [leaveID, Rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <div className="table-component">
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table" className="table-content">
          <TableHead>
            <TableRow className="table-row">
              {header?.map((el, key) => (
                <TableCell key={key}>{el.thead}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableName === "PermissionTable" ? (
              rows?.map((row, rowIndex) => {
                return (
                  <>
                    <CheckBoxRow
                      AllPermissionGroups={AllPermissionGroups}
                      tableName="PermissionTable"
                      row={row}
                      className={className}
                      options={options}
                      AllPermissions={AllPermissions}
                      rowIndex={rowIndex}
                      permission={permission}
                      userPermissions={userPermissions}
                      userId={userId}
                      GroupPermissionsTable={GroupPermissionsTable}
                      reset={reset}
                      setReset={setReset}
                      permsionPath={permsionPath}
                      permissiongroupestatus={permissiongroupestatus}
                    />
                  </>
                );
              })
            ) : Rows?.length === 0 ? (
              <p>No data found...</p>
            ) : (
              (rowsPerPage > 0
                ? Rows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Rows
              )?.map((row, key) => {
                return (
                  <Row
                    key={key}
                    row={row}
                    index={key}
                    tableName={tableName}
                    isAction={isAction}
                    isNegotiation={isNegotiation}
                    isMySpace={isMySpace}
                    isAuthorisation={isAuthorisation}
                    isCollapsed={isCollapsed}
                    allowed={allowed}
                    sortBy={sortBy}
                    getLeaveStatus={getLeaveStatus}
                    leaveTypes={leaveTypes}
                    setNotice={setNotice}
                    leaveID={leaveID}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaging && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={Rows?.length ? Rows?.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: {
              "aria-label": "page number",
            },
          }}
        />
      )}
    </div>
  );
};

export default TableComponent;
