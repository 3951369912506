import React from "react";
import "./_EmployeeDetails.scss";
import Button from "../../Button/index";
import SubTitle from "../../../components/SubTitle/SubTitle";
import { PersonalInfoDetails, ProInfoDetails } from "./EmployeeDetails";
import { deleteUser } from "../../../slices/user";
import { openModal, closeModal } from "../../../slices/modals";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../components/UploadImg/index";
import getAccess from "../../../utilities/getAccess";

const EmployeeDetails = (props) => {
  const { handleClose, id, oneUser, id_user, setNotice } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.fetchMe);

  const handlDeleteUser = () => {
    dispatch(closeModal("confirm-delete-modal"));

    dispatch(deleteUser(id_user)).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message,
          open: true,
          state: "error",
        });
      } else {
        if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });

          setTimeout(() => {
            setNotice({
              open: false,
            });
            handleClose(id);
          }, 1500);
        }
      }
    });
  };
  return (
    <>
      <div className="EmployeeDetails__header">
        <div className="employeeDetails__identity">
          <Upload
            img={oneUser?.img}
            width={"70px"}
            height={"70px"}
            uploadPhoto={true}
          />
          <div className="employeeDetails__identity_name">
            <span>{oneUser?.fullName}</span>
            <p>{oneUser?.jobTitle}</p>
          </div>
        </div>
        <div className="button_wraper">
          {getAccess(
            user?.permissions,
            user?.permissionGroups?.[0]?.permissions,
            "USER",
            "EDIT"
          ) && (
            <Button
              text={"Delete"}
              className={"delete_button"}
              type="button"
              onClick={() =>
                dispatch(
                  openModal("confirm-delete-modal", {
                    id: id_user,
                    Item: "employee",
                    deleteHandler: handlDeleteUser,
                  })
                )
              }
            />
          )}
          {getAccess(
            user?.permissions,
            user?.permissionGroups?.[0]?.permissions,
            "USER",
            "EDIT"
          ) && (
            <Button
              text={"Edit Employee"}
              className={"edit-button"}
              onClick={() =>
                dispatch(openModal("UpdateUserModal", oneUser)) ||
                handleClose(id)
              }
            />
          )}
        </div>
      </div>
      <SubTitle
        subTitle={"Personal Info"}
        className="subtitle__employee_info"
      />
      <PersonalInfoDetails
        fullName={oneUser?.fullName}
        email={oneUser?.email}
        phone={oneUser?.phone}
        birthday={oneUser?.birthday}
        gender={oneUser?.gender}
        address={oneUser?.address}
      />
      <div style={{ marginTop: "34px" }}>
        <SubTitle
          subTitle={"Professional Info"}
          className="subtitle__employee_info"
        />
      </div>
      <ProInfoDetails
        hiringDate={oneUser?.hiringDate}
        jobTitle={oneUser?.jobTitle}
        department={oneUser?.department?.name}
        status={oneUser?.status}
        role={oneUser?.role?.code}
        balance={oneUser?.balance}
        authorisationBalance={oneUser?.authorisationBalance}
        balanceLeft={oneUser?.balanceLeft}
      />
    </>
  );
};

export default EmployeeDetails;
