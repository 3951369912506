import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import AdminPanelSettingsIcon from "../../assets/images/key.png";
import Home from "../../assets/icons/Home.png";
import fire from "../../assets/icons/Fire.png";
import Groupe from "../../assets/icons/Groupe.png";
import Settings from "../../assets/icons/Settings-alt.png";
import History from "../../assets/icons/history.png";
import DropDown from "../../components/DropDownIcon/index";
import userImg from "../../assets/icons/user.png";
import ArrowDropUp from "../../components/ArrowDropUp/index.jsx";
import getAccess from "../../utilities/getAccess";
import SubMenu from "../../components/Sidebar/SidebarMenu";
import Logo from "../../components/Logo/index";
import filterTable from "../../utilities/filterTable";
import clock from "../../assets/icons/clock.png";

const Sidebar = ({ show, setSHow }) => {
  const location = useLocation();

  const user = useSelector((state) => state.user.fetchMe);

  const paths = [
    { id: 0, icon: Home, label: "Dashboard", path: "/", code: "/" },
    getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "USER",
      "EDIT"
    ) && {
      id: 1,
      icon: userImg,
      label: "Employees",
      path: "/employees",
      code: "/employees",
    },
    getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "MY LEAVES",
      "LIST"
    ) && {
      id: 2,
      icon: Groupe,
      label: "My Space",
      path: "/myspace",
    },
    getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "LEAVE",
      "LIST"
    ) && {
      id: 3,
      icon: fire,
      label: "Leaves",
      path: "/leaves",
      code: "/leaves",
    },
    getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "AUTHORISATION",
      "LIST"
    ) && {
      id: 11,
      icon: clock,
      label: "Authorisation",
      path: "/authorisations",
      code: "/authorisation",
    },

    (getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "DEPARTMENT",
      "LIST"
    ) ||
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "HOLIDAY",
        "LIST"
      ) ||
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "LEAVE TYPE",
        "LIST"
      ) ||
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "CURRENT USER",
        "EDIT"
      ) ||
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "PERMISSION",
        "LIST"
      )) && {
      id: 4,
      label: "Settings",
      icon: Settings,
      name: "settings",
      path: "/settings/departement",
      iconClosed: <DropDown />,
      iconOpened: <ArrowDropUp />,
      subNav: [
        getAccess(
          user?.permissions,
          user?.permissionGroups?.[0]?.permissions,
          "DEPARTMENT",
          "LIST"
        ) && {
          id: 5,
          label: "Departement",
          path: "/settings/departement",
        },
        getAccess(
          user?.permissions,
          user?.permissionGroups?.[0]?.permissions,
          "HOLIDAY",
          "LIST"
        ) && {
          id: 6,
          label: "Holidays",
          path: "/settings/holidays",
        },

        getAccess(
          user?.permissions,
          user?.permissionGroups?.[0]?.permissions,
          "LEAVE TYPE",
          "LIST"
        ) && {
          id: 7,
          label: "Leave settings",
          path: "/settings/leave-settings",
        },

        getAccess(
          user?.permissions,
          user?.permissionGroups?.[0]?.permissions,
          "CURRENT USER",
          "EDIT"
        ) && {
          id: 8,
          label: "User Settings",
          path: "/settings/user-settings",
        },
      ],
    },
    getAccess(
      user?.permissions,
      user?.permissionGroups?.[0]?.permissions,
      "PERMISSION",
      "LIST"
    ) && {
      id: 13,
      label: "Permissions",
      icon: AdminPanelSettingsIcon,
      name: "permissions",
      path: "/permissions/admin-users",
      iconClosed: <DropDown />,
      iconOpened: <ArrowDropUp />,
      subNav: [
        { id: 14, label: "AdminUsers", path: "/permissions/admin-users" },
        getAccess(
          user?.permissions,
          user?.permissionGroups?.[0]?.permissions,
          "PERMISSION GROUP",
          "EDIT"
        ) && {
          id: 15,
          label: "GroupPermissions",
          path: "/permissions/group-permissions",
        },
      ],
    },
    {
      id: 10,
      label: "History",
      icon: History,
      name: "history",
      path: "/history/leaves-history",
      iconClosed: <DropDown />,
      iconOpened: <ArrowDropUp />,
      subNav: [
        { id: 11, label: "Leaves history", path: "/history/leaves-history" },
        // { id: 12, label: "Log", path: "/history/log" },
      ],
    },
  ];
  let sideBar = filterTable(paths);
  sideBar = sideBar.map((el) => {
    return el?.subNav ? { ...el, subNav: filterTable(el?.subNav) } : el;
  });
  const [id, setId] = useState(null);
  const change = (id, event) => {
    setId(id);
  };
  return (
    <>
      <div className={"sidebar_main"}>
        <div onClick={() => setSHow(!show)}>
          {show ? (
            <Logo />
          ) : (
            <div className="density-smallIcon-style">
              <DensitySmallIcon />
            </div>
          )}
        </div>

        <div>
          {sideBar.map((item, index) => {
            return (
              <SubMenu
                location={location}
                setSHow={setSHow}
                loc={
                  item.label !== "History" &&
                  item.label !== "Settings" &&
                  item.label !== "Permissions" &&
                  item.path === location?.pathname
                    ? true
                    : false
                }
                show={show}
                item={item}
                key={index}
                onClick={() => change(item.id, index)}
                Id={id}
                setId={setId}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
