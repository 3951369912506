import React from "react";
import "./_select.scss";
import Select from "react-select";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const CostumSelect = ({
  maxwidth,
  onChange,
  options,
  value,
  className,
  placeholder,
  id,
  marginBottom,
  error,
  label,
  name,
  width,
  marginTop,
  isDisabled,
  resetValue,
  setResetValue,
  maxMenuHeight,
}) => {
  const colourStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: maxMenuHeight ? maxMenuHeight : "200px",
    }),
    control: (styles, isFocused) => ({
      ...styles,
      backgroundColor: "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        data: "red",
        backgroundColor: isFocused ? "#2ba7df" : "white",
        color: isFocused ? "white" : "black",

        ":active": {
          ...styles[":active"],
          backgroundColor: "#2ba7df",
          color: "white",
        },
      };
    },
  };

  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: maxwidth,
          width: width,
          marginBottom: marginBottom,
          marginTop: marginTop,
        }}
      >
        <Select
          id={id}
          placeholder={placeholder}
          value={resetValue ? "" : defaultValue(options, value)}
          onChange={(value) => {
            if (resetValue) {
              setResetValue(undefined);
            }
            onChange(value);
          }}
          options={options}
          styles={colourStyles}
          isDisabled={isDisabled}
        />

        {error ? (
          <p className="error">
            <ReportProblemIcon /> {error}
          </p>
        ) : null}
      </div>
    </div>
  );
};
export default CostumSelect;
