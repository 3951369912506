import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import UpdateDep from "./UpdateDep";

const UpdateModal = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,
  ...rest
}) => {
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title" className="modal_header">
        <span className={className}> {title} </span>
        <div className="close_button">
          <CloseIcon onClick={() => handleClose(id)} />
        </div>
      </DialogTitle>
      <DialogContent>
        <UpdateDep
          handleClose={handleClose}
          id={id}
          setNoticeDelete={data.setNoticeDelete}
          noticeDelete={data.noticeDelete}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateModal;
