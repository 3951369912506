import RoundProgBar from "../../components/RoundProgBar/index";
import SubTitle from "../../components/SubTitle/SubTitle.jsx";
import FormLeaveRequest from "./FormLeaveRequest";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaves, getMyLeaves } from "../../slices/leave";
import { useEffect, useState } from "react";
import EmployeeList from "./EmployeeList";
import React from "react";
import Calendar from "react-calendar";
import Divider from "@mui/material/Divider";

import { width2 } from "../../utilities/constants";
import { durationLeaves } from "../../utilities/durationLeaves";
import { fetchAlltype } from "../../slices/leavetype";
import getAccess from "../../utilities/getAccess";
import "react-calendar/dist/Calendar.css";
import "./_minCalendar.scss";
import "./_leaveRequest.scss";
const Leaves = (props) => {
  const {
    id,
    handleClose,
    row,
    leaveDetails,
    updateMyLeave,
    disabled,
    LeaveRequest,
  } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.fetchMe);
  const isRH = user.role?.code === "RH";
  const isEmployee = user.role?.code === "EMPLOYEE";
  const isTeamLead = user.role?.code === "TEAM LEAD";

  const { leaveTypes } = useSelector((state) => state?.leaveTypes?.leaveTypes);
  const { leaveTypeStatus } = useSelector((state) => state?.leaveTypes);
  let { addLeaveStatus, addLeaveError, AllLeaves } = useSelector(
    (state) => state.leave
  );
  AllLeaves = AllLeaves?.filter(
    (leaveStatus) => leaveStatus?.status?.code === "approved"
  );
  let MyLeaves = useSelector((state) => state.leave.getMyLeaves.leaves);

  MyLeaves = MyLeaves?.filter(
    (leaveStatus) => leaveStatus?.status?.code === "approved"
  );
  useEffect(() => {
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "MY LEAVES",
        "LIST"
      )
    ) {
      dispatch(getMyLeaves());
    }
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "LEAVE TYPE",
        "LIST"
      )
    ) {
      dispatch(fetchAlltype());
    }
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "LEAVE",
        "LIST"
      )
    ) {
      dispatch(getAllLeaves());
    }
  }, [dispatch]);

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });

  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  return (
    <div
      className={`${
        leaveDetails
          ? " margin-botom  style-for-employee  big-wrapper-apply-for-leave"
          : isEmployee
          ? "style-for-employee big-wrapper-apply-for-leave"
          : "big-wrapper-apply-for-leave"
      }`}
    >
      <div
        className={
          isEmployee || leaveDetails
            ? "left-child-wrraper margin-right"
            : "left-child-wrraper "
        }
      >
        {!leaveDetails && (
          <>
            <SubTitle subTitle={"My Leaves Balance"} />
            <div className="leave-request-progressbar ">
              {leaveTypes?.map((item, key) => (
                <>
                  <RoundProgBar
                    item={item}
                    key={key}
                    color={item?.color}
                    pathColor={item?.color}
                    leaveTypes={item?.name}
                    width={width2}
                    className="width progressbar_container"
                    value={durationLeaves(MyLeaves, item.name)}
                  />
                </>
              ))}
            </div>
          </>
        )}
        <SubTitle
          subTitle={leaveDetails ? "Leave Details" : "Apply a new Leave"}
          className={disabled ? "disabledtitle" : ""}
        />
        <div>
          <FormLeaveRequest
            updateMyLeave={updateMyLeave}
            disabled={disabled}
            leaveDetails={leaveDetails}
            row={row}
            id={id}
            handleClose={handleClose}
            addLeaveError={addLeaveError}
            addLeaveStatus={addLeaveStatus}
            setNotice={setNotice}
            notice={notice}
            leaveTypes={leaveTypes}
            leaveTypeStatus={leaveTypeStatus}
            isEmployee={isEmployee}
            isRH={isRH}
            LeaveRequest={LeaveRequest}
            isTeamLead={isTeamLead}
          />
        </div>
      </div>

      {/* {!isEmployee && (
        <Divider orientation="vertical" flexItem sx={{ m: 10 }} />
      )}

      {isTeamLead && !leaveDetails && (
        <div className="calender-big-wrapper-1">
          <div className="min-calendar-wrraper">
            <Calendar value={new Date()} />
          </div>
          <div className="LeaveItem--wrapper">
            {AllLeaves?.length === 0 ? (
              <p> No leaves ... </p>
            ) : (
              AllLeaves?.map((AllLeaves) => {
                return (
                  <>
                    <EmployeeList
                      dateStart={AllLeaves.dateStart}
                      dateEnd={AllLeaves.dateEnd}
                      fullName={AllLeaves.user.fullName}
                    />
                  </>
                );
              })
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Leaves;
