import SortBy from "./SortBy";

const RowFilter = (allowedList, list, tableName, sortBy) => {
  let leaveDate = {};
  let authorisationTime = {};
  let authorisationDate = {};
  let user = {};
  let userInfo = {};
  let newRow = [];
  let department = {};
  let status = {};
  let preview = {};

  Object.entries(list).map((row) => {
    return allowedList?.includes(row[0]) && row[0] === "date"
      ? newRow.push(["authorisationDate", { date: row[1] }])
      : allowedList?.includes(row[0]) && row[0] === "startTime"
      ? (authorisationTime.startTime = row[1])
      : allowedList?.includes(row[0]) && row[0] === "endTime"
      ? ((authorisationTime.endTime = row[1]),
        newRow.push(["authorisationTime", authorisationTime]))
      : allowedList?.includes(row[0]) && row[0] === "dateStart"
      ? (leaveDate.dateStart = row[1])
      : allowedList?.includes(row[0]) && row[0] === "dateEnd"
      ? ((leaveDate.dateEnd = row[1]), newRow.push(["leaveDate", leaveDate]))
      : allowedList?.includes(row[0]) && row[0] === "user"
      ? ((user.fullName = row[1]?.fullName),
        (user.jobTitle = row[1]?.jobTitle),
        (user.img = row[1]?.img
          ? `${process.env.REACT_APP_IMG_URL}/${row[1]?.img}`
          : undefined),
        newRow.push(["user", user]))
      : tableName === "users"
      ? allowedList?.includes(row[0]) && row[0] === "fullName"
        ? (user.fullName = row[1])
        : allowedList?.includes(row[0]) && row[0] === "img"
        ? (user.img = row[1]
            ? `${process.env.REACT_APP_IMG_URL}/${row[1]}`
            : undefined)
        : allowedList?.includes(row[0]) && row[0] === "jobTitle"
        ? ((user.jobTitle = row[1]), newRow.push(["user", user]))
        : allowedList?.includes(row[0]) && row[0] === "email"
        ? (userInfo.email = row[1])
        : allowedList?.includes(row[0]) && row[0] === "phone"
        ? ((userInfo.phone = row[1]), newRow.push(["userInfo", userInfo]))
        : allowedList?.includes(row[0]) && row[0] === "department"
        ? ((department.name = row[1]?.name),
          newRow.push(["department", department]))
        : allowedList?.includes(row[0]) && row[0] === "status"
        ? ((status.name = row[1]), newRow.push(["status", status]))
        : ""
      : tableName === "leave-types"
      ? row[0] === "name"
        ? (newRow.push(row), (preview.name = row[1]))
        : row[0] === "img"
        ? (newRow.push([
            row[0],
            row[1] ? `${process.env.REACT_APP_IMG_URL}/${row[1]}` : undefined,
          ]),
          (preview.img = `${process.env.REACT_APP_IMG_URL}/${row[1]}`))
        : row[0] === "color"
        ? ((preview.color = row[1]), newRow.push(["preview", preview]))
        : ""
      : allowedList?.includes(row[0])
      ? newRow.push(row)
      : "";
  });

  return SortBy(newRow, sortBy);
};

export default RowFilter;
