import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ProgressbarComponent from "./ProgressbarComponent.jsx";
import "./_progbar.scss";

const RoundProgBar = (props) => {
  const { leaveTypes, color, className, width, value, pathColor } = props;
  const leaveBalance = value !== undefined ? value[value.length - 1] : null;


  return (
    <>
      <ProgressbarComponent
        label={leaveTypes}
        color={color}
        width={width}
        className={className}
      >
        <CircularProgressbar
          value={leaveBalance}
          text={`${leaveBalance} Days`}
          styles={buildStyles({
            pathColor: pathColor,
            textColor: "black",
            strokeLinecap: "butt",
          })}
        />
      </ProgressbarComponent>
    </>
  );
};

export default RoundProgBar;
