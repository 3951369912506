const Search = (table, search, tableName) => {
  let fileredByDepartment = [];
  let fileredByAddress = [];
  let filteredByStatus = [];
  let filteredByJobTitle = [];
  let filteredByName = [];
  let Rows = [];
  Rows = table?.filter((row) => {
    return search == null
      ? row
      : (tableName === "leave-types" &&
          row.name.trim().toLowerCase().includes(search.name)) ||
        (tableName === "department" &&
          row.name.trim().toLowerCase().includes(search.name)) ||
        (tableName === "leaves-history" &&
          row?.user?.fullName.trim().toLowerCase().includes(search.name))
      ? row
      : "";
  });
  fileredByDepartment = search?.departmentID
    ? table?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row?.department?._id.includes(search?.departmentID)
            ? row
            : ""
          : "";
      })
    : table;

  filteredByStatus = search?.status
    ? fileredByDepartment?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.status
              .trim()
              .toLowerCase()
              .includes(search?.status?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.departmentID
    ? fileredByDepartment
    : table;

  filteredByJobTitle = search?.jobTitle
    ? filteredByStatus?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.jobTitle
              .trim()
              .toLowerCase()
              .includes(search?.jobTitle?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : table;

  fileredByAddress = search?.address
    ? filteredByJobTitle?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row?.address
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.address?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.jobTitle
    ? filteredByJobTitle
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : table;

  filteredByName = search?.name
    ? fileredByAddress?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.fullName
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.name?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.address
    ? fileredByAddress
    : search?.jobTitle
    ? filteredByJobTitle
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : table;

  return tableName === "leave-types" ||
    tableName === "department" ||
    tableName === "leaves-history"
    ? Rows
    : filteredByName;
};

export default Search;
