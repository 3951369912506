import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../slices/modals";
import AddHolyModal from "../../views/Settings/holidays/AddHolyModal";
import EmpDetModal from "../../views/EmployeeDetails/EmpDetModal";
import AddEmplFoms from "../../views/AddNewEmployee/AddEmpModal";
import ApplyForLeave from "../../views/LeaveRequest/ApplyForLeaveModal";
import AddNewDepModal from "../../views/Settings/Departement/AddNewDepModal";
import UpdateModal from "../../views/Settings/Departement/UpdateModal";
import LeaveSettingsModal from "../../views/Settings/LeaveSettings/LeaveSettingsModal";
import ApplyForNegotiationModal from "../../views/Leaves/ApplyForNegotiationModal";
import UpdateUserModal from "../../views/EmployeeDetails/UpdateUserModal";
import UpdateLeaveSettingsModal from "../../views/Settings/LeaveSettings/UpdateLeaveSettingsModal";
import ApplyForLeaveDetailsModal from "../../views/LeaveRequest/ApplyForLeaveDetailsModal";
import ConfirmDeleteModal from "../Modals/ConfirmDeleteModal";
import RejectLeaveRequestModal from "../Modals/RejectLeaveRequestModal";
import ConfirmDeletePerGrpModal from "../../views/Permissions/GroupPermissions/ConfirmDeletePerGrpModal";
import ApplyForAuthorisationModal from "../../views/Authorisation/ApplyForAuthorisationModal";
import ApplyForAuthorisationModalDetails from "../../views/Authorisation/ApplyForAuthorisationModalDetails";
import EditPasswordModal from "../../views/Settings/userSettings/editPasswordModal";
const ModalsProvider = () => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };

  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  return (
    <>
      <AddHolyModal
        id="AddHolyModal"
        open={modalState("AddHolyModal", "open")}
        data={modalState("AddHolyModal", "data")}
        handleClose={handleClose}
        title={"Add New Holiday "}
        editTitle={"Edit holiday "}
        className="title-modals"
      />
      <EmpDetModal
        id="EmpDetModal"
        open={modalState("EmpDetModal", "open")}
        data={modalState("EmpDetModal", "data")}
        handleClose={handleClose}
        title="Employee Details"
        className="title-modals"
      />
      <AddEmplFoms
        id="AddEmplFoms"
        open={modalState("AddEmplFoms", "open")}
        data={modalState("AddEmplFoms", "data")}
        handleClose={handleClose}
        title="Add New Employee"
        className="title-modals"
      />

      <ApplyForLeave
        id="ApplyForLeave"
        open={modalState("ApplyForLeave", "open")}
        data={modalState("ApplyForLeave", "data")}
        handleClose={handleClose}
        title="Apply For a Leave"
        className="title-modals"
      />

      <ApplyForAuthorisationModal
        id="ApplyForAuthorisationModal"
        open={modalState("ApplyForAuthorisationModal", "open")}
        data={modalState("ApplyForAuthorisationModal", "data")}
        handleClose={handleClose}
        title="Apply for authorisation"
        className="title-modals"
      />

      <ApplyForAuthorisationModalDetails
        id="ApplyForAuthorisationModalDetails"
        open={modalState("ApplyForAuthorisationModalDetails", "open")}
        data={modalState("ApplyForAuthorisationModalDetails", "data")}
        handleClose={handleClose}
        title="Apply for authorisation Details"
        className="title-modals"
      />
      <ApplyForLeaveDetailsModal
        id="ApplyForLeaveDetailsModal"
        open={modalState("ApplyForLeaveDetailsModal", "open")}
        data={modalState("ApplyForLeaveDetailsModal", "data")}
        handleClose={handleClose}
        title="Leave Request Details"
        className="title-modals"
      />
      <AddNewDepModal
        id="AddNewDepModal"
        open={modalState("AddNewDepModal", "open")}
        data={modalState("AddNewDepModal", "data")}
        handleClose={handleClose}
        title="Add New Department "
        className="title-modals"
      />
      <UpdateModal
        id="UpdateDep"
        open={modalState("UpdateDep", "open")}
        data={modalState("UpdateDep", "data")}
        handleClose={handleClose}
        title="Edit Department "
        className="title-modals"
      />
      <ApplyForNegotiationModal
        id="ApplyForNegotiationModal"
        open={modalState("ApplyForNegotiationModal", "open")}
        data={modalState("ApplyForNegotiationModal", "data")}
        handleClose={handleClose}
        title="Apply For Negotiation "
        className="title-modals"
      />
      <LeaveSettingsModal
        id="LeaveSettingsModal"
        open={modalState("LeaveSettingsModal", "open")}
        data={modalState("LeaveSettingsModal", "data")}
        handleClose={handleClose}
        title="Add New Leave Type "
        className="title-modals"
      />
      <UpdateLeaveSettingsModal
        id="UpdateLeaveSettingsModal"
        open={modalState("UpdateLeaveSettingsModal", "open")}
        data={modalState("UpdateLeaveSettingsModal", "data")}
        handleClose={handleClose}
        title="Update Leave Type "
        className="title-modals"
      />
      <UpdateUserModal
        id="UpdateUserModal"
        open={modalState("UpdateUserModal", "open")}
        data={modalState("UpdateUserModal", "data")}
        handleClose={handleClose}
        title="Update User "
        className="title-modals"
      />
      <ConfirmDeleteModal
        id="confirm-delete-modal"
        open={modalState("confirm-delete-modal", "open")}
        data={modalState("confirm-delete-modal", "data")}
        handleClose={handleClose}
      />
      <ConfirmDeletePerGrpModal
        id="ConfirmDeletePerGrpModal"
        open={modalState("ConfirmDeletePerGrpModal", "open")}
        data={modalState("ConfirmDeletePerGrpModal", "data")}
        handleClose={handleClose}
      />
      <RejectLeaveRequestModal
        id="reject-leave-request-modal"
        open={modalState("reject-leave-request-modal", "open")}
        data={modalState("reject-leave-request-modal", "data")}
        handleClose={handleClose}
      />
      <EditPasswordModal
        id="editPasswordModal"
        open={modalState("editPasswordModal", "open")}
        data={modalState("editPasswordModal", "data")}
        handleClose={handleClose}
      />
    </>
  );
};
export default ModalsProvider;
