import React, { useEffect, useState } from "react";
import SubTitle from "../../../components/SubTitle/SubTitle";
import Card from "../../../components/Card/Card.jsx";
import PersonalInfo from "../../AddNewEmployee/PersonalInfo";
import ProfessionalInfo from "../../AddNewEmployee/ProfessionalInfo";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import Upload from "../../../components/UploadImg/index";
import { useDispatch } from "react-redux";
import { updateMe, updateUser } from "../../../slices/user";
import "./_userSettings.scss";
import { useSelector } from "react-redux";
import Notice from "../../../components/Notice";
import LoadingScreen from "../../../components/LoadingScreen";
import "../../EmployeeDetails/_style.scss";
import moment from "moment";
import editPasswordModal from "./editPasswordModal";
import Click from "../../../assets/icons/Click.png";
import "moment-timezone";
import { openModal } from "../../../slices/modals";

moment.tz.setDefault("Africa/Tunis");
const UserSettings = ({ onUser, updateUserTest, id, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.fetchMe);
  const isRH = user?.role?.code === "RH";
  const isSuperAdmin = user.role?.code === "SUPER ADMIN";

  const fetchMeStatus = useSelector((state) => state.user.fetchMeStatus);
  const [fieldChange, setFieldChange] = useState(false);

  const [myImg, setMyImg] = useState(false);
  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });
  useEffect(() => {
    setTimeout(() => {
      setNotice((prevState) => ({
        ...prevState,
        open: false,
      }));
    }, 6000);
  }, [notice]);

  let initialValues = {
    id: onUser?._id,

    fullName: onUser ? onUser.fullName : user?.fullName,
    email: onUser ? onUser.email : user?.email,
    phone: onUser ? onUser.phone : user?.phone,
    birthday: onUser
      ? {
          day: new Date(onUser?.birthday).getDate(),
          month: new Date(onUser?.birthday).getMonth() + 1,
          year: new Date(onUser?.birthday).getFullYear(),
        }
      : {
          day: new Date(user?.birthday).getDate(),
          month: new Date(user?.birthday).getMonth() + 1,
          year: new Date(user?.birthday).getFullYear(),
        },

    gender: onUser ? onUser.gender : user?.gender,
    address: onUser ? onUser.address : user?.address,
    hiringDate: onUser
      ? {
          day: new Date(onUser?.hiringDate).getDate(),
          month: new Date(onUser?.hiringDate).getMonth() + 1,
          year: new Date(onUser?.hiringDate).getFullYear(),
        }
      : {
          day: new Date(user?.hiringDate).getDate(),
          month: new Date(user?.hiringDate).getMonth() + 1,
          year: new Date(user?.hiringDate).getFullYear(),
        },

    jobTitle: onUser ? onUser.jobTitle : user?.jobTitle,
    department: onUser ? onUser?.department?._id : user?.department?._id,
    status: onUser ? onUser.status : user?.status,
    role: onUser ? onUser?.role?._id : user?.role?._id,
    balance: onUser ? onUser?.balance : user?.balance,
    authorisationBalance: onUser
      ? onUser?.authorisationBalance
      : user?.authorisationBalance,
    submit: null,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is Required!"),
    fullName: Yup.string().required("Name is Required!"),
    phone: Yup.number("Enter your phone number")
      .min(10000000, "Phone number should be of minimum 8 numbers length")
      .max(99999999, "Phone number should be of maximum 8 numbers length")
      .required("Phone number is required"),

    gender: Yup.string().required("Gender is Required!"),
    birthday: Yup.object().required("Birthday is Required!"),
    address: Yup.string().required("address is Required!"),
    hiringDate: Yup.object().required("HiringDate is Required!"),
    status: Yup.string().required("Status is Required!"),
    balance: Yup.string().required("Balance is Required!"),
    authorisationBalance: Yup.string().required(
      "Authorisation Balance is Required!"
    ),
  });

  const onSubmit = async (values, submitProps) => {
    const data = {
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
      birthday: new Date(
        values.birthday?.month +
          "/" +
          values.birthday?.day +
          "/" +
          values.birthday?.year
      ),
      gender: values.gender,
      address: values.address,
      jobTitle: values.jobTitle,
      hiringDate: new Date(
        values.hiringDate?.month +
          "/" +
          values.hiringDate?.day +
          "/" +
          values.hiringDate?.year
      ),
      img: values.img,
      status: values.status,
      balance: values.balance,
      authorisationBalance: values.authorisationBalance,
      department: values.department,
      role: values.role,
    };

    updateUserTest === true && fieldChange
      ? await dispatch(updateUser({ ...data, id: onUser._id })).then((res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
              setTimeout(() => {
                handleClose(id);
              }, 1500);
            }
          }
        })
      : fieldChange &&
        (await dispatch(updateMe(data)).then((res) => {
          if (res?.error) {
            setNotice({
              text: res?.error?.message,
              open: true,
              state: "error",
            });
            submitProps.setStatus({ success: false });
            submitProps.setSubmitting(true);
          } else {
            if (res?.payload?.message) {
              setNotice({
                text: res?.payload?.message,
                open: true,
                state: "success",
              });
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(true);
            }
          }
        }));
  };

  return (
    <>
      {fetchMeStatus === "succeeded" ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            formik.dirty === true
              ? setFieldChange(true)
              : setFieldChange(false);
            return (
              <>
                <Notice
                  open={notice.open}
                  text={notice.text}
                  state={notice.state}
                />
                <Form className={"user-settings-wrraper"}>
                  <div className="header-upload-user-info">
                    <Upload
                      isUpoding={true}
                      uploadPhoto={true}
                      formik={formik}
                      img={onUser ? onUser?.img : user?.img}
                      setMyImg={setMyImg}
                      myImg={myImg}
                      width={"70px"}
                      height={"70px"}
                      isUpdate={true}
                    />
                    <div className="ta-btns-group">
                      <Button
                        className=" BlueButton btn-add "
                        text="Save All Data"
                        type="submit"
                        disabled={!formik.isValid}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      onUser ? "form-total-wrraper" : "form-main-container"
                    }
                  >
                    <Card className={"cardOne-wrapper"}>
                      <SubTitle
                        subTitle={"Personal Info"}
                        className="blueColor subtitle__employee_info"
                      />
                      <PersonalInfo
                        setFieldChange={setFieldChange}
                        formik={formik}
                        user={user}
                        onUser={onUser}
                        otherstyle="otherstyle"
                        disabled={isRH || isSuperAdmin ? false : true}
                      />
                      {!updateUserTest && (
                        <div className="edit_password_wrapper">
                          <span>{"Password"}</span>
                          <div>
                            <Button
                              type="button"
                              text="Edit Your Password"
                              onClick={() =>
                                dispatch(openModal("editPasswordModal"))
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Card>

                    <Card
                      className={
                        onUser ? "card1-to-wrapper " : "card-to-wrapper"
                      }
                    >
                      <div className="employee-info-main ">
                        <SubTitle
                          subTitle={"Professional Info"}
                          className="blueColor subtitle__employee_info"
                        />
                        <ProfessionalInfo
                          disabled={isRH || isSuperAdmin ? false : true}
                          user={user}
                          onUser={onUser}
                          formik={formik}
                          otherstyle="otherstyle"
                        />
                      </div>
                    </Card>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default UserSettings;
