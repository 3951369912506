import React from "react";
import "./_header.scss";
import DropdownMenuNotif from "../../components/NotifItem/DropDownNotif";
import DropdownMenuLogOut from "../../components/LogOutMenu/index";

const Header = ({ Avatar, title, show, setSHow, user }) => {
  const isSuperAdmin = user.role?.code === "SUPER ADMIN";

  const handlShowSidebar = () => {
    setSHow(show);
  };
  return (
    <div className="header__container">
      <div className="Header__title" onClick={handlShowSidebar}>
        <span> {title} </span>
      </div>

      <div className="Header__notif">
        {!isSuperAdmin && <DropdownMenuNotif />}
        <DropdownMenuLogOut Avatar={Avatar} />
      </div>
    </div>
  );
};

export default Header;
