import React from "react";
import { getFormattedDate } from "../../utilities/getFormattedDate";
const EmployeeLeaveDetails = (props) => {
  const { data } = props;

  return (
    <div>
      <div className="label-wrapper">
        <span>Full Name: </span>
        <p>{data?.user?.fullName}</p>
      </div>
      <div className="label-wrapper">
        <span>Periode: </span>
        <p>{`${getFormattedDate(new Date(data?.dateStart))}  -  
        ${getFormattedDate(new Date(data?.dateEnd))}
        `}</p>
      </div>

      <div className="label-wrapper">
        <span> Leave Type: </span>
        <p>{data?.type?.name}</p>
      </div>
      <div className="label-wrapper-description">
        <span> Description: </span>
        <p>{data?.description}</p>
      </div>
    </div>
  );
};

export default EmployeeLeaveDetails;
