import React from "react";

import "./_index.scss";

const Button = (props) => {
  const { text, leftIcon, rightIcon, ...rest } = props;
  return (
    <button {...rest}>
      {leftIcon && typeof leftIcon === "string" ? (
        <img src={leftIcon} alt={leftIcon} />
      ) : (
        leftIcon
      )}

      {text}
      {rightIcon && typeof rightIcon === "string" ? (
        <img src={rightIcon} alt={rightIcon} />
      ) : (
        rightIcon
      )}
    </button>
  );
};
export default Button;
