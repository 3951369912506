import Sidebar from "./Sidebar";
import Header from "./Header";
import "./_index.scss";
import SubTitle from "../../components/SubTitle/SubTitle";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMe } from "../../slices/user";

const MainLayout = ({ children }) => {
  const [show, setSHow] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.fetchMe);

  useEffect(() => {
    if (user?.length === 0) dispatch(fetchMe());
  }, []);

  return (
    <>
      <div className="main-layout">
        <>
          <Sidebar setSHow={setSHow} show={show} />
          <Divider orientation="vertical" flexItem />
        </>

        <div className="container">
          <Header show={show} setSHow={setSHow} user={user} />
          <div className="children_container">
            <SubTitle />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
