import React from "react";
import { allLeavesDay } from "../../../utilities/calculateTimimg";
import Card from "../../Card/Card";
import "./_LeaveBalance.scss";

const LeaveBalance = (props) => {
  const { color, leave, leaveDays, img, balanceLeft, user } = props;
  const nbWorkedMonths = allLeavesDay(user?.hiringDate) / 30.5;
  let totalLeaveDays = nbWorkedMonths * user?.balance;

  const leaveBalance =
    leaveDays !== undefined ? leaveDays[leaveDays?.length - 1] : null;

  return (
    <Card>
      <div className="LeaveBalance_container">
        {typeof img === "string" ? (
          <img src={`${process.env.REACT_APP_IMG_URL}/${img}`} alt="img" />
        ) : (
          <div className="icon-style">{img}</div>
        )}
        <div className="LeaveBalance_containe_title">
          <span style={{ color: color }}>{leave}</span>
        </div>
        <div className="LeaveBalance_containe__leaveDays">
          <span>{balanceLeft ? balanceLeft?.toFixed(2) : leaveBalance} </span>
          {balanceLeft && (
            <div className="LeaveBalance_Tdays">{`/${totalLeaveDays}`}</div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default LeaveBalance;
