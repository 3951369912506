import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(
  {
    dropdownMenuMain: {
      display: "flex",
      justifyContent: "space-between",
      "& .MuiPaper-root ": {
        top: "80px !important",
        maxHeight: "79% !important",
        maxWidth: "700px !important",
        borderRadius: "16px !important",
      },
      "& .jss1": {
        borderRadius: "16px !important",
      },
      "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
        {
          //  borderRadius: "16px",
          "& .MuiList-padding": {
            marginBottom: " 0px !important",
          },
        },
    },
  },
  { index: 1 }
);
