import React from "react";
import EmployeeItem from "./EmployeeItem";
import { getFormattedDate } from "../../../utilities/getFormattedDate";
export const PersonalInfoDetails = (props) => {
  const { fullName, email, phone, birthday, gender, address } = props;

  return (
    <div>
      <EmployeeItem label="Full Name" info={fullName} />
      <EmployeeItem label="Email " info={email} />
      <EmployeeItem label="Address" info={address} />
      <EmployeeItem label="Phone Number" info={phone} />
      <EmployeeItem
        label="Birthday"
        info={getFormattedDate(new Date(birthday))}
      />
      <EmployeeItem label="Gender" info={gender} />
    </div>
  );
};

export const ProInfoDetails = (props) => {
  const {
    hiringDate,
    jobTitle,
    department,
    status,
    role,
    balance,
    authorisationBalance,
  } = props;

  return (
    <>
      <EmployeeItem
        label="Hiring Date"
        info={getFormattedDate(new Date(hiringDate))}
      />
      <EmployeeItem label="Job Title" info={jobTitle} />
      <EmployeeItem label="Department" info={department} />
      <EmployeeItem label="Satuts" info={status} />
      <EmployeeItem label="Role" info={role} />
      <EmployeeItem label="Monthly Balance" info={balance} />
      <EmployeeItem
        label="Authorisation Balance"
        info={authorisationBalance != null ? authorisationBalance : "---"}
      />
    </>
  );
};
