import React, { useState } from "react";
import Button from "../../../components/Button";
import "./_AddNewHolidaySys.scss";
import { holidayType } from "../../../utilities/constants";
import { closeModal, openModal } from "../../../slices/modals";

import { deleteHoliday } from "../../../slices/holidays";
import Input from "../../../components/Form/Input";
import DatePicker from "../../../components/Form/DatePicker";
import Select from "../../../components/Form/Select";
const HolidayForm = ({
  formik,
  event,
  setUpdated,
  dispatch,
  handleClose,
  id,
  setNoticeDeleteHoliday,
}) => {
  const [resetValue, setResetValue] = useState(false);

  const resetHandler = () => {
    setResetValue(true);
  };
  const handldelete = async () => {
    dispatch(closeModal("confirm-delete-modal"));

    await dispatch(deleteHoliday(event._id)).then((result) => {
      if (result?.error) {
        setNoticeDeleteHoliday({
          text: result?.error.message,
          open: true,
          state: "error",
        });
        setTimeout(() => {
          setNoticeDeleteHoliday({
            open: false,
            state: "error",
          });
        }, 1500);
      } else {
        if (result?.payload?.message) {
          setUpdated(false);
          setNoticeDeleteHoliday({
            text: result?.payload?.message,
            open: true,
            state: "success",
          });

          setTimeout(() => {
            setNoticeDeleteHoliday({
              open: false,
            });
          }, 1500);
        }
      }
    });
  };
  return (
    <>
      <div className="holiday-sys-wrraper">
        <Input type="text" label="Holiday Name" name="name" />
      </div>
      <div className="holiday-sys-wrraper">
        <div className="holiday-sys-wrraper " style={{ marginBottom: "40px" }}>
          <DatePicker
            id="HolidayDate"
            formik={formik}
            type="date"
            label="Date"
            name="HolidayDate"
            error={
              formik.touched?.HolidayDate?.from === true &&
              formik.values.HolidayDate.to === null
                ? "Date is required!"
                : null
            }
          />
          <Select
            label="Type"
            id="type"
            setResetValue={setResetValue}
            resetValue={resetValue}
            options={holidayType}
            value={formik.values.type}
            onChange={(value) => formik.setFieldValue("type", value.value)}
            error={
              formik.errors.type && formik.touched.type
                ? formik.errors.type
                : null
            }
            className={`${
              formik.errors.type && formik.touched.type
                ? " select selectFormHoliday  error-select"
                : " select selectFormHoliday"
            }`}
          />
        </div>
      </div>

      <div className="ta-btns-group ">
        <Button
          type="submit"
          text={event ? "Edit " : "Save"}
          className={
            !formik.isValid ||
            formik.values.HolidayDate.to === null ||
            formik.isSubmitting
              ? " blueButton disabled-button "
              : "blueButton "
          }
        />
        {event && (
          <>
            <Button
              type="button"
              text="Delete"
              className="redButton"
              onClick={() =>
                dispatch(
                  handleClose(id) ||
                    openModal("confirm-delete-modal", {
                      id: event._id,
                      Item: "holiday",
                      deleteHandler: handldelete,
                    })
                )
              }
            />
          </>
        )}

        {!event && (
          <Button
            className="whiteButton"
            type="reset"
            text="Cancel"
            onClick={resetHandler}
          />
        )}
      </div>
    </>
  );
};

export default HolidayForm;
