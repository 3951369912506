import React from "react";
import { Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import editIcon from "../../../assets/icons/Edit.png";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../slices/modals";
import UserInformations from "./UserInformations";
import {
  UserInfo,
  JobInfo,
  UserContactInfo,
  leavesInfo,
} from "../../../utilities/constants";
import Avatar from "@mui/material/Avatar";
import "./_collapse.scss";
import getAccess from "../../../utilities/getAccess";
import { allLeavesDay } from "../../../utilities/calculateTimimg";

const Collapse = ({ setOpen, open, row }) => {
  const currentUser = useSelector((state) => state.user.fetchMe);
  const dispatch = useDispatch();
  let user = Object.entries(row);
  const nbWorkedMonths = allLeavesDay(row?.hiringDate) / 30.5;
  let totalLeaveDays = nbWorkedMonths * row?.balance;
  user = [
    ...user,
    ["total-leave-days", totalLeaveDays.toFixed(2)],
    ["leave-balance", row?.balance ? (row?.balance * 12).toFixed(2) : "---"],
  ];

  user = row?.balance ? user : [...user, ["balance", "---"]];
  return (
    <div className="collapse">
      <Grid container>
        <Grid className="collapse-content">
          <div className="user-img">
            {row?.img ? (
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${row?.img}`}
                alt="user-img"
              />
            ) : (
              <Avatar />
            )}
          </div>

          <div className="user-data">
            <UserInformations data={UserInfo} user={user} />
            <UserInformations data={JobInfo} user={user} />
            <UserInformations data={leavesInfo} user={user} />
            <UserInformations
              data={UserContactInfo}
              user={user}
              className="user-contact-information"
            />

            <div className="collapse-action">
              <CloseIcon onClick={() => setOpen(!open)} />
              {getAccess(
                currentUser?.permissions,
                currentUser?.permissionGroups?.[0]?.permissions,
                "USER",
                "VIEW"
              ) && (
                <InfoOutlinedIcon
                  onClick={() =>
                    dispatch(openModal("EmpDetModal", { id: row?._id }))
                  }
                />
              )}
              {getAccess(
                currentUser?.permissions,
                currentUser?.permissionGroups?.[0]?.permissions,
                "USER",
                "EDIT"
              ) && (
                <img
                  src={editIcon}
                  alt="edit-icon"
                  onClick={() => dispatch(openModal("UpdateUserModal", row))}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Collapse;
